<template>
  <MasterDetail
    formTitle="Cadastro de NF de Serviço de Terceiros"
    :cols="cols"
    :opts="opts"
    :customResource="customResource"
    descriptionProperty="nro_documento"
    :contextOptions="options"
    :actionBarButtons="actionBarButtons"
    has-monthly-filter
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { TipoImportacaoEnum } from "@/core/enums/importacoes";
export default {
  components: {
    MasterDetail,
  },
  computed: {
    actionBarButtons: function () {
      const items = [
        {
          text: "Enviar Arquivos NF's de Serviços",
          icon: "mdi-file-import-outline",
          show: this.client.isClient ? this.userHasAccess("Importacoes.dispendios.planilhaDeTerceiros") : this.userHasAccess("Importacoes.resource.index"),
          action: () => {
            const route = this.client.isClient
              ? { name: "listagem-importacoes-servicos-terceiros-anexo" }
              : { name: "listagem-importacoes", query: { tipo: TipoImportacaoEnum.ANEXO_NF_SERVICOS_TERCEIROS } };
            this.$router.push(route);
          },
        },
        {
          text: "Importar NF's de Serviços (Planilha)",
          icon: "mdi-file-import-outline",
          show: this.client.isClient ? this.userHasAccess("Importacoes.dispendios.anexosDeServicosDeTerceiros") : this.userHasAccess("Importacoes.resource.index"),
          action: () => {
            const route = this.client.isClient
              ? { name: "listagem-importacoes-servicos-terceiros-planilha" }
              : { name: "listagem-importacoes", query: { tipo: TipoImportacaoEnum.FISCAL_NF_TERCEIROS } };
            this.$router.push(route);
          },
        },
      ];

      return items.filter(({ show }) => show);
    },
    client: function () {
      return this.getClient();
    },
    customResource: function () {
      const that = this;
      const resource = this.apiResource(`/v1/fiscal/nfservico/${this.client.clientId}`);
      return {
        ...resource,
        get(...args) {          
          return resource.get(...args).then((result) => {
            that.resourceTerceiros.get().then((response) => {
              that.opts.terceiros = response;
            });
            return result
          })
        },
      };
    },
    resourceTerceiros: function () {
      return this.apiResource(`/v1/fiscal/terceiro/${this.client.clientId}/servico`);
    },
    options: function () {
      return this.userHasAccessToAction("index")
        ? [{
          name: "Ver Itens da NF",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          cb: (row) => {
            const terceiro = this.opts.terceiros.find(({ id }) => id == row.terceiroId);
            this.$router.push({
              name: "dispendio-servicos-terceiros-detalhes",
              params: { nfId: row.id },
              query: { criterio_rateio: terceiro?.criterio_rateio },
            });
          },
        }]
        : [];
    },
  },
  created: function () {
    
  },
  data: function () {
    return {
      selectedCriterioRateio: {},
      cols: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "terceiroId",
          name: "Fornecedor/Terceiro",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "terceiros", key: "id", name: "razao_social" },
        },
        {
          key: "nro_documento",
          name: "Nro do Documento",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "data",
          name: "Data",
          align: -1,
          type: this.$fieldTypes.DATE,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "valor_nf",
          name: "Valor",
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "mes_base",
          name: "Mês Prestação Serviço",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "total_horas",
          name: "Total Horas",
          type: this.$fieldTypes.BIGTIME,
          colSize: 6,
        },
        {
          key: "descricao",
          name: "Descrição",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "codigo_servico_nf",
          name: "Código de serviço da NF",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "cnae_primario",
          name: "CNAE",
        },
        {
          key: "observacao",
          name: "Observação",
          type: this.$fieldTypes.TEXTAREA,
          colSize: 12,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        terceiros: [],
      },
    };
  },
};
</script>
